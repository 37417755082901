import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { LoginSignupService } from './login-signup.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdService {

  constructor(private logsign_service: LoginSignupService) { }
}


//Admin before login check
@Injectable({
  providedIn: 'root'
})
export class AdminAuthGaurdLogin implements CanActivate {
	constructor(private router: Router) { }
	canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
	  let role = sessionStorage.getItem("role");
	   if(role == "admin"){
		   this.router.navigate(["/admin-dashboard"]);
		   return false;
	   }else{
		 return true;
	   }
	}
}

//Admin after login check
@Injectable({
  providedIn: 'root'
})
export class AdminAuthGaurdService {
  constructor(private router: Router, private logsign_service: LoginSignupService) { }
  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
  	  const currentUser = this.logsign_service.currentUserValue;
        if (currentUser) {
            // logged in so return true
            return true;
        }else{
		 this.router.navigate(["/auth/signin"]);
		 return false;
	  }
  }
}

