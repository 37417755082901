
<div style="text-align: center;" >
  <a href="/admin">   <img class="logosuperpick" src="/assets/logo.png" /></a>

</div>
 
<!-- <div id="unity-container" class="unity-desktop">
  <canvas id="unity-canvas" width=960 height=600 ></canvas>
  <div id="unity-loading-bar">

      <div id="loading">LOADING...</div>
      <div id="unity-progress-bar-empty">
          <div id="unity-progress-bar-full"></div>
      </div>
  </div>
  <div id="unity-warning"> </div>
  <div id="unity-footer">
   
  </div>
</div> -->

<div id="unity-container" class="unity-desktop">
  <canvas id="unity-canvas" width=960 height=600></canvas>
  <div id="unity-loading-bar">
  
 <div id="loading">LOADING...</div>
<div id="unity-progress-bar-empty">
      <div id="unity-progress-bar-full"></div>
    </div>
  </div>
  <div id="unity-mobile-warning">
   
  </div>
  <div id="unity-footer">
  
  </div>
</div>
<!-- <div id="unity-container" class="unity-desktop">
  <canvas id="unity-canvas" width=960 height=600 tabindex="-1"></canvas>
  <div id="unity-loading-bar">
      <div id="unity-logo"></div
      <div id="loading">LOADING...</div>
      <div id="unity-progress-bar-empty">
          <div id="unity-progress-bar-full"></div>
      </div>
  </div>
  <div id="unity-warning"> </div>
  <div id="unity-footer">
    <div id="unity-webgl-logo"></div>
    <div id="unity-fullscreen-button"></div>
    <div id="unity-build-title">Nasyonal</div>
  </div>
</div> -->





<div style="margin-top: 670px;">

  <router-outlet></router-outlet>
</div>

