<div class="row">

    <div class="header">
    
     <img class="iconmenu" src="assets/pick4.png">

    </div>


</div>

<div *ngFor="let number of numbers" class="content">
  
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12 "><h1 class="headernumber">Winning Numbers:</h1> </div>
  

    </div>


    <div class="row">
        <div class="col-lg-8 col-sm-8 col-xs-8"> <div class="float-right">  {{number.time}} <img *ngIf="number.isnight == 'true'" class="icondaynigh" src="/assets/moon.png" /> <img *ngIf="number.isnight == 'false'" class="icondaynigh" src="/assets/sun.png" /> </div></div>


    </div>

    <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12"> <div class="number"> {{number.createdOn | date: 'dd/MM/yyyy'}}  {{number.time}}</div></div>


    </div>

    <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12"> 
        
            <div *ngFor="let winning_no of StringToArray(number.number)"  class="iconball"> <div class="labelball2">{{winning_no }}</div> </div> 
        


    </div>


</div>

</div>