import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../core/services/api.service';
import { BehaviorSubject, Observable, from, of, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
//const baseUrl = `${environment.apiUrl}/Account/Login`;
import { User } from '../../core/models/object-model';

@Injectable({
  providedIn: 'root'
})
export class LoginSignupService {
	public login_url = environment.apiUrl + '/Account/Login';;
  public reset_password = environment.apiUrl + '/Account/Forgotpassword'
	private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
	 user_data;
  constructor(private http: HttpClient, private apiService: ApiService,  private router: Router,
        private route: ActivatedRoute) { 
		 this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
	}
	
	public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
  
   authLogin(login_dto): Observable<any>{
		return this.apiService.post(this.login_url,login_dto).pipe(map(user => {
			 this.user_data = user.data;
				this.user_data.token = user.token;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                sessionStorage.setItem('currentUser', JSON.stringify(this.user_data));
                this.currentUserSubject.next(user);
                return user;
            }));;
	}

  resetPassword(emailId):Observable<any>
  {
      
    return this.apiService.get(this.reset_password + "/"+emailId);


  }
	
	logout(): Observable<any> {
        // remove user from local storage to log user out
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
		return;

    }
}
