import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-frontend',
  templateUrl: './frontend.component.html',
  styleUrls: ['./frontend.component.scss']
})
export class FrontendComponent implements OnInit {
  title = 'Nasyonal';
  router: string;
  constructor(private _router: Router) {

      this.router= _router.url;
      // console.log(this.router);
   }

  ngOnInit(): void {
  }

}
