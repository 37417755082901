import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { FrontendComponent } from './theme/layout/frontend/frontend.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { AdminAuthGaurdService} from './shared/services/auth-gaurd.service';
import { HomeComponent} from './components/home/home.component';
import { Pick2Component } from './components/home/pick2/pick2.component';
import { Pick3Component } from './components/home/pick3/pick3.component';
import { Pick4Component } from './components/home/pick4/pick4.component';
const routes: Routes = [
    { 
      path: '', 
      component: FrontendComponent,
      children: [
        { path: '', component: HomeComponent, pathMatch: 'full'},
        {path:'pick2',component:Pick2Component  }, 
        {path:'pick3',component:Pick3Component  },
        {path:'pick4',component:Pick4Component  }
      ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'winning_numbers',
        pathMatch: 'full'
      },
      {
        path: 'winning_numbers',
	   	canActivate: [AdminAuthGaurdService],
        loadChildren: () => import('./components/pages/tables/tbl-datatable/tbl-datatable.module').then(module => module.TblDatatableModule)
      },
	    {
        path: 'payouts',
		canActivate: [AdminAuthGaurdService],
        loadChildren: () => import('./components/pages/tables/payout/payout.module').then(module => module.PayoutModule)
      }
     
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./components/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
