<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <span>{{ currentUser.name }}</span>
          <a href="javascript:" (click)="logout()" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
      </div>
    </div>
  </li>
</ul>
