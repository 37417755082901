import { AfterContentInit, Component, ElementRef, OnInit } from '@angular/core';
import { WinningNumberService } from 'src/app/shared/services/winning-number.service';

@Component({
  selector: 'app-pick3',
  templateUrl: './pick3.component.html',
  styleUrls: ['./pick3.component.scss']
})
export class Pick3Component implements OnInit,AfterContentInit {
  numbers: any = [];

  constructor(private winning_number_service: WinningNumberService,private elementRef: ElementRef) { }
  ngAfterContentInit(): void {
    
    this.elementRef.nativeElement.ownerDocument
    .body.style.backgroundImage = "url('/assets/background.jpg')";


  }

  ngOnInit(): void {
    var data = {'type' : 3};
    this.winning_number_service.detail(data).subscribe(res => { 
      this.numbers = res.data;
   });
  }

  StringToArray(string){
    return Array.from(String(string));
  }
}
