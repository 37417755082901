import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WinningNumberService } from 'src/app/shared/services/winning-number.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit,AfterViewInit {
  
  winning_number: any=[];

  pick2Numbers: any = [];

  pick3Numbers: any = [];

  pick4Numbers: any = [];

  constructor(private router:Router, private winning_number_service: WinningNumberService,private elementRef: ElementRef) { }
  
  
  ngAfterViewInit(): void {
       
  }

	  navigate(route:string)
  {

    var url = './' + route;
    this.router.navigate([route]);

  }
  
  ngOnInit(): void {

   var data = {'type' : 2};
   this.winning_number_service.detail(data).subscribe(res => { 
      this.pick2Numbers = res.data;

  
   });

   var data = {'type' : 3};
  this.winning_number_service.detail(data).subscribe(res => { 
     this.pick3Numbers = res.data;
  });

  var data = {'type' : 4};
  this.winning_number_service.detail(data).subscribe(res => { 
    this.pick4Numbers = res.data;
  });


  }

}
