import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { LoginSignupService } from '../../../../../shared/services/login-signup.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
   public currentUser;

  constructor(private logsign_service: LoginSignupService,  private router: Router) { }

  ngOnInit() {
	this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
	}
  
  
  logout() {
        // remove user from local storage to log user out
        this.logsign_service.logout();
		this.router.navigateByUrl('/admin');
		location.reload();
    }
}
