<div class="row">
    <div class="col-lg-12 text-center">
        <div id="banner">

        <img id="bannerimg" src="assets/centerbanner.jpg">


        </div>
    </div>
</div>

<div class="row ">
    <div class="col-lg-12">
        <div id="numbers">

            <div class="row ">

                <div class="col-lg-12 ">
                   <div class="pick2-box">
                
                    <div class="row">

                        <div class="col-lg-6 col-md-6 col-sm-6 title"><div class="title-number-text"><img src="/assets/pick2icon.png" /></div> </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 title-history "> <div class="title-history-text"><img src="/assets/history.png" /> </div>  </div>

                    </div>
                   
                    <div class="row content-box">
                 
                        <div class="col-lg-5 col-md-5 col-sm-5 title">Winning Numbers</div>
                        <div class="col-lg-7 col-md-7 col-sm-7 title"> Time </div>


                    </div>

                    <div class="row-top content-box " *ngFor="let number of pick2Numbers">
                    
                    <div class="row ">
                    
                        <div class="col-lg-12">

                            <div class="row">

                                <div class="col-lg-5 col-md-5 col-sm-5">
                                   
                                    <div class="number-box">
                                     
                                        <div class="date-label">{{number.createdOn | date: 'MM/dd/yyyy'}}  </div>
                                        <div class="label-ball">{{number.number[0]}}</div>  
                                        <div class="label-ball">{{number.number[1]}}</div>  

                                    </div>
 
                                </div>

                                <div class="col-lg-7 col-md-7 col-sm-7"> 
                               
                                    <div class="time-box">
                                  
                                    {{number.time}} 
                                    <img *ngIf="number.isnight == 'true'" class="icondaynigh" src="/assets/moon.png" /> 
                                    <img *ngIf="number.isnight == 'false'" class="icondaynigh" src="/assets/sun.png" /> 
                                    </div>
                            
                                </div>
        
                             </div>
        

                        </div>

                    </div>
                                         
                        
                    </div>
                    </div>
                </div>

            </div>

            <div class="row row-top">

                <div class="col-lg-12 ">
                    <div class="pick3-box">
                       
                        
                     
                        <div class="row">

                            <div class="col-lg-6 col-md-6 col-sm-6 title"><div class="title-number-text"><img src="/assets/pick3icon.png" /></div> </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 title-history "> <div class="title-history-text"> <img src="/assets/history.png" /> </div>  </div>
    
                        </div>
                       
                       
                       <div class="row content-box">
                 
                            <div class="col-lg-5 col-md-5 col-sm-5 title">Winning Numbers</div>
                            <div class="col-lg-7 col-md-7 col-sm-7 title"> Time </div>
    
    
                        </div>
                     
                         <div class="row-top content-box" *ngFor="let number of pick3Numbers">
                         
                         <div class="row ">
                         
                             <div class="col-lg-12">
     
                                 <div class="row">
     
                                     <div class="col-lg-5 col-md-5 col-sm-5">
                                        
                                         <div class="number-box">
                                          
                                             <div class="date-label">{{number.createdOn | date: 'MM/dd/yyyy'}}  </div>
                                             <div class="label-ball">{{number.number[0]}}</div>  
                                             <div class="label-ball">{{number.number[1]}}</div>  
                                             <div class="label-ball">{{number.number[2]}}</div>  
     
                                         </div>
      
                                     </div>
     
                                     <div class="col-lg-7 col-md-7 col-sm-7"> 
                                    
                                         <div class="time-box">
                                       
                                         {{number.time}} 
                                         <img *ngIf="number.isnight == 'true'" class="icondaynigh" src="/assets/moon.png" /> 
                                         <img *ngIf="number.isnight == 'false'" class="icondaynigh" src="/assets/sun.png" /> 
                                         </div>
                                 
                                     </div>
             
                                  </div>
             
     
                             </div>
     
                         </div>
                                              
                             
                         </div>
                    </div>
                </div>

       

            </div>

            <div class="row row-top">

                <div class="col-lg-12 ">
                    <div class="pick4-box">
                        <div class="row">

                            <div class="col-lg-6 col-md-6 col-sm-6 title"><div class="title-number-text"><img src="/assets/pick4icon.png" /></div> </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 title-history "> <div class="title-history-text"><img src="/assets/history.png" /> </div>  </div>
    
                        </div>
                       
                        <div class="row content-box">
                 
                            <div class="col-lg-5 col-md-5 col-sm-5 title">Winning Numbers</div>
                            <div class="col-lg-7 col-md-7 col-sm-7 title"> Time </div>
    
    
                        </div>
                    
                         <div class="row-top content-box" *ngFor="let number of pick4Numbers">
                         
                         <div class="row ">
                         
                             <div class="col-lg-12">
     
                                 <div class="row">
     
                                     <div class="col-lg-5 col-md-5 col-sm-5">
                                        
                                         <div class="number-box">
                                          
                                             <div class="date-label">{{number.createdOn | date: 'MM/dd/yyyy'}}  </div>
                                             <div class="label-ball">{{number.number[0]}}</div>  
                                             <div class="label-ball">{{number.number[1]}}</div>  
                                             <div class="label-ball">{{number.number[2]}}</div>  
                                             <div class="label-ball">{{number.number[3]}}</div> 
     
                                         </div>
      
                                     </div>
     
                                     <div class="col-lg-7 col-md-7 col-sm-7"> 
                                    
                                         <div class="time-box">
                                       
                                         {{number.time}} 
                                         <img *ngIf="number.isnight == 'true'" class="icondaynigh" src="/assets/moon.png" /> 
                                         <img *ngIf="number.isnight == 'false'" class="icondaynigh" src="/assets/sun.png" /> 
                                         </div>
                                 
                                     </div>
             
                                  </div>
             
     
                             </div>
     
                         </div>
                                              
                             
                         </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>

<div class="row footer"></div>