import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WinningNumber } from '../../core/models/object-model';

export class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Injectable({
  providedIn: 'root'
})
export class WinningNumberService {

  private winning_number_url = environment.apiUrl + '/WinningNum';
   private winning_number_add_url = environment.apiUrl + '/WinningNum/AddWinningNum';

  constructor(private apiService: ApiService, private http: HttpClient) { }
  
  /* all():Observable<any>{
	 return this.apiService.get(this.winning_number_url)
  }
   all(tableQuery): Observable<DataTablesResponse> {
	return this.apiService.get(this.winning_number_url,tableQuery);
  }*/
  
  all(data): Observable<any> {
	return this.apiService.get(this.winning_number_url + '/WinningNumList', data);
  }
  
  add(number_dto): Observable<any> {
	  return this.apiService.post(this.winning_number_add_url, number_dto);
  }
  
  delete(id): Observable<any>{
	  return this.apiService.get(this.winning_number_url+ '/RemoveWinNum/' + id);
  }

  latest_record(): Observable<any> {
    return this.apiService.get(this.winning_number_url + '/WinningResults');
  }


  detail(data): Observable<any> {
    return this.apiService.get(this.winning_number_url + '/LastPickResults',data);
  }
}
