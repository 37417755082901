import { AfterContentInit, Component, ElementRef, OnInit } from '@angular/core';
import { WinningNumberService } from 'src/app/shared/services/winning-number.service';

@Component({
  selector: 'app-pick2',
  templateUrl: './pick2.component.html',
  styleUrls: ['./pick2.component.scss']
})
export class Pick2Component implements OnInit,AfterContentInit {
  numbers: any = [];
  /*numbers = [{time:'8:00 PM',date:'1/2/2022 8:00 PM',num1:'1',num2:'2', isnight:'true'}
  ,{time:'7:30 PM',date:'1/2/2022 7:30 PM',num1:'7',num2:'4',isnight:'true'}
  ,{time:'5:00 PM',date:'1/2/2022 5:00 PM',num1:'3',num2:'4',isnight:'false'} ]*/
  constructor(private winning_number_service: WinningNumberService,private elementRef: ElementRef) {


   }

  ngAfterContentInit(): void {
  
    this.elementRef.nativeElement.ownerDocument
    .body.style.backgroundImage = "url('/assets/background.jpg')";


  }

  ngOnInit(): void {
    var data = {'type' : 2};
    this.winning_number_service.detail(data).subscribe(res => { 
      this.numbers = res.data;
   });
  }

  StringToArray(string){
    return Array.from(String(string));
  }
}
